"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _store = require("@/api/store");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      StoreProduct: {
        id: null,
        uuid: null,
        name: '',
        store: null,
        price: null
      },
      buttonText: null
    };
  },

  async created() {
    if (this.$route.params.sProdId) {
      this.buttonText = _i18n.default.t('StoreProduct.Edit');
      this.fetchData();
    } else {
      this.buttonText = _i18n.default.t('StoreProduct.Add');
      this.getStoreData();
    }
  },

  methods: {
    async fetchData() {
      this.loading = true;
      (0, _store.getStoreProduct)(this.$route.params.sProdId).then(response => {
        this.StoreProduct = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },

    async getStoreData() {
      this.loading = true;
      (0, _store.getStore)(this.$route.params.id).then(response => {
        this.StoreProduct.store = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.loading = true;

        if (this.$route.params.sProdId) {
          (0, _store.editStoreProduct)(this.StoreProduct).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreProduct.StoreProductupdated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/products`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreProduct.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            }); // console.log(err)

            this.loading = false;
          });
        } else {
          (0, _store.createStoreProduct)(this.StoreProduct).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreProduct.StoreProductcreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/products`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreProduct.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    }

  }
};
exports.default = _default;