var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.StoreProduct, "label-width": "auto" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.StoreProduct.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProduct, "id", $$v)
                  },
                  expression: "StoreProduct.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("StoreProduct.Store"), prop: "store" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.StoreProduct.store.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProduct.store, "name", $$v)
                  },
                  expression: "StoreProduct.store.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("StoreProduct.ProductName"),
                prop: "name",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.StoreProduct.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProduct, "name", $$v)
                  },
                  expression: "StoreProduct.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("StoreProduct.Price"), prop: "price" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.StoreProduct.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProduct, "price", $$v)
                  },
                  expression: "StoreProduct.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-success" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: {
                    to: "/stores/" + _vm.$route.params.id + "/products",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }