"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _supplier = require("@/api/supplier");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      supplier: {
        id: null,
        contactName: null,
        name: null,
        shortname: null,
        tel: null,
        email: null,
        address: null,
        remark: null
      },
      buttonText: null
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    if (this.$route.params.id) {
      this.buttonText = _i18n.default.t('Supplier.Edit');
      this.fetchData();
    } else {
      this.buttonText = _i18n.default.t('Supplier.Add');
    }
  },

  methods: {
    onNameBlur() {
      if (this.supplier.name && this.supplier.name.length <= 8 && !this.supplier.shortname) {
        this.supplier.shortname = this.supplier.name;
      }
    },

    async fetchData() {
      this.loading = true;
      (0, _supplier.getSupplier)(this.$route.params.id).then(response => {
        this.supplier = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.loading = true;

        if (this.$route.params.id) {
          return (0, _supplier.editSupplier)(this.supplier).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('Supplier.Supplierupdated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/suppliers`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('Supplier.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            }); // console.log(err)

            this.loading = false;
          });
        } else {
          return (0, _supplier.createSupplier)(this.supplier).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('Supplier.Suppliercreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/suppliers`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('Supplier.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    }

  }
};
exports.default = _default;