"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _errorParse = require("@/utils/error-parse");

var _supplier = require("@/api/supplier");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        perPage: 10,
        listAll: true,
        search: null
      },
      total: 0
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _supplier.getSuppliers)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((supplier, index) => {
          supplier.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    deleteSupplier(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('Supplier.ConfirmDeleteMessage'), _i18n.default.t('Supplier.Confirm'), {
        confirmButtonText: _i18n.default.t('Supplier.Delete'),
        cancelButtonText: _i18n.default.t('Supplier.Cancel'),
        type: 'warning'
      }).then(() => (0, _supplier.deleteSupplier)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('Supplier.Recorddeleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;