"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _rawMaterial = require("@/api/rawMaterial");

var _store = require("@/api/store");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _vueSelect = _interopRequireDefault(require("vue-select"));

require("vue-select/src/scss/vue-select.scss");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default // Pagination

  },

  data() {
    return {
      loading: false,
      rawMaterials: [],
      storeProduct: {
        id: null,
        uuid: null,
        name: null,
        store: {
          id: null,
          name: null,
          uuid: null
        },
        price: null
      },
      StoreProductCombo: {
        id: null,
        storeProductId: null,
        rawMaterialId: null,
        percentage: null
      },
      disableRawMaterial: false,
      buttonText: null
    };
  },

  computed: {
    /* filtered() {
      return this.warehouseAreas
    },
    paginated() {
      return this.warehouseAreas.slice(0, this.limit)
    }, */
    hasNextPage() {
      return this.rawMaterials.length < this.rawMaterialToShow;
    }

  },

  async created() {
    (0, _store.getStoreProduct)(this.$route.params.sProdId).then(storeProduct => {
      this.storeProduct = storeProduct.data;

      if (!this.$route.params.sProdRawMaterialId) {
        this.StoreProductCombo.storeProductId = this.storeProduct.uuid;
      }
    });

    if (this.$route.params.sProdRawMaterialId) {
      this.buttonText = _i18n.default.t('StoreProductCombo.Edit');
      this.fetchData();
    } else {
      // getRawMaterials({ storeId: this.$route.params.id, limit: 9999, listAll: true }).then(rawMaterials => {
      //   this.rawMaterials = rawMaterials.data
      // })
      await this.fetchRawMaterial();
      this.buttonText = _i18n.default.t('StoreProductCombo.Add');
    }
  },

  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },

  methods: {
    async fetchRawMaterial(keyword = '', loading = null) {
      if (!this.rawMaterialOptions) {
        this.rawMaterialOptions = {
          page: 1
        };
      }

      if (loading) {
        loading(true);
        this.observer.disconnect();
        this.rawMaterialOptions = { ...this.rawMaterialOptions,
          page: 1,
          keyword
        };
      } else {
        this.rawMaterialOptions = { ...this.rawMaterialOptions,
          keyword
        };
      }

      this.rawMaterialOptions = { ...this.rawMaterialOptions,
        keyword
      };
      return (0, _rawMaterial.getRawMaterials)(this.rawMaterialOptions).then(response => {
        this.rawMaterialToShow = response.meta.total;

        if (loading) {
          this.rawMaterials = response.data.map(rawMaterial => {
            return {
              uuid: rawMaterial.uuid,
              sku: rawMaterial.sku
            };
          });
          this.onOpen();
        } else {
          this.rawMaterials = this.rawMaterials.concat(response.data.map(rawMaterial => {
            return {
              uuid: rawMaterial.uuid,
              sku: rawMaterial.sku
            };
          }));
        }
      }).catch(error => console.error(error)).finally(() => {
        if (loading) {
          loading(false);
        }
      });
    },

    async onOpenRawMaterialFilter() {
      if (this.hasNextPage) {
        await this.$nextTick();

        if (this.$refs.load !== undefined) {
          this.observer.observe(this.$refs.load);
        }
      }
    },

    onCloseRawMaterialFilter() {
      this.observer.disconnect();
    },

    async infiniteScroll([{
      isIntersecting,
      target
    }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.rawMaterialOptions.page++;
        await this.fetchRawMaterial();
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async fetchData() {
      this.loading = true;
      (0, _store.getStoreProductCombo)(this.$route.params.sProdRawMaterialId).then(response => {
        this.StoreProductCombo = response.data;
        this.rawMaterials = [this.StoreProductCombo.rawMaterial];
        this.StoreProductCombo.storeProductId = this.storeProduct.uuid;
        this.StoreProductCombo.rawMaterialId = this.StoreProductCombo.rawMaterial.uuid;
        this.disableRawMaterial = true;
      }).finally(() => {
        this.loading = false;
      });
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.loading = true;

        if (this.$route.params.sProdRawMaterialId) {
          (0, _store.editStoreProductCombo)(this.StoreProductCombo).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreProductCombo.StoreProductComboupdated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/products/${this.$route.params.sProdId}/combo`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreProductCombo.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            }); // console.log(err)

            this.loading = false;
          });
        } else {
          (0, _store.createStoreProductCombo)(this.StoreProductCombo).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('StoreProductCombo.StoreProductCombocreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/stores/${this.$route.params.id}/products/${this.$route.params.sProdId}/combo`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('StoreProductCombo.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    }

  }
};
exports.default = _default;