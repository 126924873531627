var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.StoreProductCombo, "label-width": "auto" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.StoreProductCombo.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProductCombo, "id", $$v)
                  },
                  expression: "StoreProductCombo.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("StoreProductCombo.Store"),
                prop: "store",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.storeProduct.store.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.storeProduct.store, "name", $$v)
                  },
                  expression: "storeProduct.store.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("StoreProductCombo.ProductName"),
                prop: "name",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.storeProduct.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.storeProduct, "name", $$v)
                  },
                  expression: "storeProduct.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseAreaRawMaterial.RawMaterial"),
                prop: "rawMaterialId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "StoreProductCombo.RequiredRawMaterialMessage"
                    ),
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.rawMaterials,
                  label: "sku",
                  reduce: function (rawMaterial) {
                    return rawMaterial.uuid
                  },
                  disabled: _vm.disableRawMaterial,
                },
                on: {
                  open: _vm.onOpenRawMaterialFilter,
                  close: _vm.onCloseRawMaterialFilter,
                  search: _vm.fetchRawMaterial,
                },
                scopedSlots: _vm._u([
                  {
                    key: "list-footer",
                    fn: function () {
                      return [
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hasNextPage,
                                expression: "hasNextPage",
                              },
                            ],
                            ref: "load",
                            staticClass: "loader",
                          },
                          [
                            _vm._v(
                              "\n            Loading more options...\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.StoreProductCombo.rawMaterialId,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProductCombo, "rawMaterialId", $$v)
                  },
                  expression: "StoreProductCombo.rawMaterialId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("StoreProductCombo.PercentageOf"),
                prop: "percentage",
              },
            },
            [
              _c("el-input-number", {
                attrs: { precision: 2, min: 0, size: "small" },
                model: {
                  value: _vm.StoreProductCombo.percentage,
                  callback: function ($$v) {
                    _vm.$set(_vm.StoreProductCombo, "percentage", $$v)
                  },
                  expression: "StoreProductCombo.percentage",
                },
              }),
              _vm._v(" %\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isOffline,
                    icon: "el-icon-success",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: {
                    to:
                      "/stores/" +
                      _vm.$route.params.id +
                      "/products/" +
                      _vm.$route.params.sProdId +
                      "/combo",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }