var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.store,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("PurchaseRequests.Store")) + ": "),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.store.name))]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Import Sale" } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: false,
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.importDate,
                  callback: function ($$v) {
                    _vm.importDate = $$v
                  },
                  expression: "importDate",
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "PreviewUpload",
                  staticClass: "preview-upload",
                  attrs: {
                    action:
                      _vm.uploadDomain +
                      "/api/v1/stores/" +
                      _vm.store.uuid +
                      "/sales/upload-sales",
                    data: {
                      importDate: _vm.formatDate(_vm.importDate),
                      actualPrice: _vm.actualPrice,
                    },
                    headers: _vm.uploadHeader,
                    "on-error": _vm.onUploadError,
                    "on-success": _vm.onUploadSuccess,
                    accept:
                      "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    drag: "",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Drop file here or "),
                    _c("em", [_vm._v("click to upload")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v("Template: "),
                      _c("strong", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.uploadDomain + "/templates/sales.xlsx",
                              target: "_blank",
                            },
                          },
                          [_vm._v("click here")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.actualUploadErrors.length
                ? [
                    _c(
                      "ul",
                      _vm._l(_vm.actualUploadErrors, function (error) {
                        return _c("li", { key: error.row }, [
                          _vm._v(
                            "Row " +
                              _vm._s(error.row) +
                              " " +
                              _vm._s(error.attribute) +
                              " = " +
                              _vm._s(error.values[error.attribute]) +
                              " : " +
                              _vm._s(error.errors.join(" "))
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-button", { on: { click: _vm.onCancel } }, [
        _vm._v(_vm._s(_vm.$t("PurchaseRequests.Back"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }