var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.user,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.user.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "uuid", $$v)
                  },
                  expression: "user.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Group", prop: "group" } },
            [
              _vm.tenants && _vm.tenants.length
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: !(_vm.currentUser && _vm.currentUser.tenant),
                        placeholder: "Group",
                      },
                      model: {
                        value: _vm.user.tenant.uuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.user.tenant, "uuid", $$v)
                        },
                        expression: "user.tenant.uuid",
                      },
                    },
                    _vm._l(_vm.tenants, function (tenant) {
                      return _c("el-option", {
                        key: tenant.name,
                        attrs: { label: tenant.name, value: tenant.uuid },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.user.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "name", $$v)
                  },
                  expression: "user.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "HKID", prop: "hkid" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.hkid,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "hkid", $$v)
                  },
                  expression: "user.hkid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Tel", prop: "tel" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.user.tel,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "tel", $$v)
                  },
                  expression: "user.tel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Joining Date", prop: "joiningDate" } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.user.joiningDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "joiningDate", $$v)
                  },
                  expression: "user.joiningDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Role", prop: "roles" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.user.roles,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "roles", $$v)
                    },
                    expression: "user.roles",
                  },
                },
                _vm._l(_vm.roles, function (item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.id,
                      attrs: {
                        disabled: !_vm.checkboxPermission(item),
                        label: item.id,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.checkedRole($event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name) + "\n        ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "role-desc" }, [
                _c("div", { staticClass: "role-function" }, [
                  _vm._v("*Role Function*"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "role-info" },
                  _vm._l(_vm.asyncRoutes, function (node) {
                    return _c("div", { key: node.name }, [
                      _c("span", [_vm._v(_vm._s(node.name))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(node.role))]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.currentUser.roles.find(function (role) {
            return ["Store Manager", "Operation Manager", "Admin"].includes(
              role.name
            )
          }) && _vm.checkFullTimeRole()
            ? _c(
                "el-form-item",
                { attrs: { label: "Leave", prop: "leaves" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.user.leaves,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "leaves", $$v)
                        },
                        expression: "user.leaves",
                      },
                    },
                    _vm._l(_vm.leaves, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.uuid, attrs: { label: item.uuid } },
                        [
                          _vm._v(
                            _vm._s(
                              item.name +
                                (item.quota > 0
                                  ? "(" +
                                    item.quota +
                                    " day per " +
                                    item.refill_period +
                                    ")"
                                  : "")
                            )
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkFullTimeRole()
            ? _c(
                "el-form-item",
                { attrs: { label: "Monthly Salary", prop: "monthlySalary" } },
                [
                  _c("el-input-number", {
                    attrs: { step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.user.monthlySalary,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "monthlySalary", $$v)
                      },
                      expression: "user.monthlySalary",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPartTimeRole()
            ? _c(
                "el-form-item",
                { attrs: { label: "Hourly Wage", prop: "hourlyWage" } },
                [
                  _c("el-input-number", {
                    attrs: { step: 0.1, "step-strictly": "" },
                    model: {
                      value: _vm.user.hourlyWage,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "hourlyWage", $$v)
                      },
                      expression: "user.hourlyWage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkFullTimeRole()
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Working Hours / Day",
                    prop: "workingHoursPerDay",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { step: 0.5, "step-strictly": "" },
                    model: {
                      value: _vm.user.workingHoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "workingHoursPerDay", $$v)
                      },
                      expression: "user.workingHoursPerDay",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkFullTimeRole()
            ? _c(
                "el-form-item",
                { attrs: { label: "Is head office?", prop: "headOffice" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex font" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "請選擇..." },
                          model: {
                            value: _vm.user.headOffice,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "headOffice", $$v)
                            },
                            expression: "user.headOffice",
                          },
                        },
                        _vm._l(_vm.headOffice, function (viewSalary) {
                          return _c("el-option", {
                            key: viewSalary.name,
                            attrs: {
                              label: viewSalary.name,
                              value: viewSalary.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Store"),
                prop: "stores",
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.stores,
                  label: "name",
                  multiple: "",
                  reduce: function (store) {
                    return store.uuid
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selected-option-container",
                    fn: function (ref) {
                      var option = ref.option
                      var deselect = ref.deselect
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "vs__selected",
                            style: _vm.getStoresStyles(option),
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.stores.find(function (store) {
                                    return store.uuid === option.uuid
                                  })
                                    ? _vm.stores.find(function (store) {
                                        return store.uuid === option.uuid
                                      }).name
                                    : ""
                                ) +
                                "\n            "
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "vs__deselect",
                                attrs: {
                                  type: "button",
                                  title: "Deselect",
                                  "aria-label": "Deselect",
                                },
                                on: {
                                  click: function ($event) {
                                    return deselect(option)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "10",
                                      height: "10",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.user.stores,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "stores", $$v)
                  },
                  expression: "user.stores",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Attachment", prop: "attachment" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.uploadDomain + "/api/v1/user-attachment/upload",
                    "before-remove": _vm.beforeRemove,
                    data: _vm.uploadData,
                    "file-list": _vm.fileList,
                    headers: _vm.uploadHeader,
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                    drag: "",
                    multiple: "",
                    name: "attachment[]",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Drop file here or "),
                    _c("em", [_vm._v("click to upload")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("jpg/png files with a size less than 500kb")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Password", prop: "password" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "password" },
                model: {
                  value: _vm.user.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Confirm password",
                prop: "password_confirmation",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "password" },
                model: {
                  value: _vm.user.password_confirmation,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password_confirmation", $$v)
                  },
                  expression: "user.password_confirmation",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _vm.currentUser !== null &&
            (_vm.currentUser.roles.find(function (role) {
              return ["Admin"].includes(role.name)
            }) ||
              _vm.currentUser.uuid === _vm.user.uuid)
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isOffline,
                          icon: "el-icon-success",
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-close" }),
                        _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                      ]
                    ),
                  ],
                  1
                )
              : _vm.currentUser !== null &&
                _vm.currentUser.roles.find(function (role) {
                  return ["Operation Manager"].includes(role.name)
                }) &&
                !_vm.user.userRoles.find(function (role) {
                  return ["Admin", "Operation Manager"].includes(role.name)
                })
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isOffline,
                          icon: "el-icon-success",
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-close" }),
                        _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        attrs: { to: "/users" },
                      },
                      [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Back")))]
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }