var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "max-width": "1200px" },
          attrs: {
            model: _vm.salary,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "日期", prop: "dateRange" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": " - ",
                  "start-placeholder": _vm.$t(
                    "RawMaterialScheduledPrice.StartDate"
                  ),
                  "end-placeholder": _vm.$t(
                    "RawMaterialScheduledPrice.EndDate"
                  ),
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Store"),
                prop: "stores",
              },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.allStoreChecked,
                    callback: function ($$v) {
                      _vm.allStoreChecked = $$v
                    },
                    expression: "allStoreChecked",
                  },
                },
                [_vm._v("All")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.salary.stores,
                    callback: function ($$v) {
                      _vm.$set(_vm.salary, "stores", $$v)
                    },
                    expression: "salary.stores",
                  },
                },
                _vm._l(_vm.stores, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.uuid, attrs: { label: item.uuid } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-success" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }