var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.store,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.store.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.store, "id", $$v)
                  },
                  expression: "store.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: "30" },
                model: {
                  value: _vm.store.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.store, "name", $$v)
                  },
                  expression: "store.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Description", prop: "description" } },
            [
              _c("vue-editor", {
                attrs: {
                  "custom-modules": _vm.customModulesForEditor,
                  "editor-options": _vm.editorSettings,
                  "match-visual": false,
                },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Group", prop: "group" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Group",
                    disabled: !_vm.user || !!_vm.userTenant,
                  },
                  model: {
                    value: _vm.store.tenantId,
                    callback: function ($$v) {
                      _vm.$set(_vm.store, "tenantId", $$v)
                    },
                    expression: "store.tenantId",
                  },
                },
                _vm._l(_vm.tenants, function (tenant) {
                  return _c("el-option", {
                    key: tenant.name,
                    attrs: { label: tenant.name, value: tenant.uuid },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isOffline,
                    icon: "el-icon-success",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/stores" },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }