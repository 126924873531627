var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", gutter: 0 },
            },
            [
              _vm.user.roles.find(function (role) {
                return ["Operation Manager", "Admin"].includes(role.name)
              })
                ? _c(
                    "el-col",
                    { attrs: { span: 24, md: 5, lg: 5 } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "el-button el-button--primary",
                          class: { "is-disabled": _vm.isOffline },
                          attrs: { to: _vm.isOnline ? "/users/add" : "#" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-plus" }),
                          _vm._v(" " + _vm._s(_vm.$t("route.AddUser"))),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24, md: 19, lg: 19 } },
                [
                  _c(
                    "el-row",
                    {
                      attrs: {
                        type: "flex",
                        justify: _vm.device === "mobile" ? "start" : "end",
                        gutter: 10,
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { "padding-left": "5px" } },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: {
                                inline: true,
                                "label-position":
                                  _vm.device === "mobile" ? "top" : "left",
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fetchData($event)
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Role",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.listQuery.role,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "role", $$v)
                                        },
                                        expression: "listQuery.role",
                                      },
                                    },
                                    [
                                      !!_vm.user.roles.find(function (role) {
                                        return ["Admin"].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Admin",
                                              value: "admin",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return ["Admin"].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Warehouse Manager",
                                              value: "warehouse.manager",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return ["Admin"].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Procurement Manager",
                                              value: "procurement.manager",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return [
                                          "Admin",
                                          "Operation Manager",
                                        ].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Operation Manager",
                                              value: "operation.manager",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return [
                                          "Admin",
                                          "Operation Manager",
                                        ].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Store Manager",
                                              value: "store.manager",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return [
                                          "Admin",
                                          "Operation Manager",
                                          "Store Manager",
                                        ].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Staff Role (Full time)",
                                              value: "staff",
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.user.roles.find(function (role) {
                                        return [
                                          "Admin",
                                          "Operation Manager",
                                          "Store Manager",
                                        ].includes(role.name)
                                      })
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "Staff Role (Part time)",
                                              value: "staff.parttime",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "PurchaseRequests.Stores"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.listQuery.storeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "storeId",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.storeId",
                                      },
                                    },
                                    _vm._l(_vm.stores, function (store) {
                                      return _c("el-option", {
                                        key: store.uuid,
                                        attrs: {
                                          label: store.name,
                                          value: store.uuid,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.fetchData($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.listQuery.search,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "search", $$v)
                                      },
                                      expression: "listQuery.search",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "default",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.fetchData },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: { disabled: !scope.row.active } }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Username" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.active
                      ? _c("span", { staticClass: "disabled" }, [
                          _vm._v("[Terminated] "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { class: { disabled: !scope.row.active } }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Role", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: { disabled: !scope.row.active } }, [
                      _vm._v(
                        _vm._s(
                          scope.row.roles
                            .map(function (item) {
                              return item.name
                            })
                            .join(", ")
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store"), width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "div",
                          [
                            _vm._l(
                              scope.row.stores.slice(0, 3).map(function (item) {
                                return { name: item.name, uuid: item.uuid }
                              }),
                              function (store, index) {
                                return _c(
                                  "span",
                                  {
                                    key: store.uuid,
                                    class: { disabled: !scope.row.active },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "el-button el-button--text",
                                        staticStyle: {
                                          "max-width": "100px",
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                        },
                                        attrs: {
                                          to: "/stores/roster/" + store.uuid,
                                        },
                                      },
                                      [_vm._v(_vm._s(store.name))]
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        index > 1 ||
                                          index + 1 >= scope.row.stores.length
                                          ? ""
                                          : ", "
                                      ) + "\n          "
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.stores.length > 3 ? "..." : ""
                                ) +
                                "\n        "
                            ),
                          ],
                          2
                        )
                      : _c("span", { class: { disabled: !scope.row.active } }, [
                          _vm._v(
                            _vm._s(
                              scope.row.stores
                                .filter(function (item) {
                                  return _vm.user.stores.some(function (store) {
                                    return store.uuid === item.uuid
                                  })
                                })
                                .slice(0, 3)
                                .map(function (item) {
                                  return item.name
                                })
                                .join(", ") +
                                (scope.row.stores.filter(function (item) {
                                  return _vm.user.stores.some(function (store) {
                                    return store.uuid === item.uuid
                                  })
                                }).length > 3
                                  ? "..."
                                  : "")
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              "min-width": "105",
              label: _vm.$t("Order.Actions"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active === 1
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "el-button el-button--success is-plain",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: { to: "/users/edit/" + scope.row.uuid },
                          },
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(" " + _vm._s(_vm.$t("Order.Edit"))),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.active === 1 &&
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    }) &&
                    scope.row.roles.find(function (role) {
                      return ["Staff (Full time)"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled:
                                _vm.isOffline ||
                                scope.row.uuid === _vm.user.uuid,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteUser(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.active === 1 &&
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    }) &&
                    scope.row.roles.find(function (role) {
                      return ["Staff (Full time)"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.leaveSummary(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Leave")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.active === 1 &&
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.isOffline,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setNotActive(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Terminate")]
                        )
                      : scope.row.active === 0 &&
                        _vm.user.roles.find(function (role) {
                          return ["Operation Manager", "Admin"].includes(
                            role.name
                          )
                        })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              plain: "",
                              disabled: _vm.isOffline,
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setActive(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Unterminate")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }