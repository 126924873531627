"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _user = require("@/api/user");

var _store = require("@/api/store");

var _vue2Editor = require("vue2-editor");

var _vuex = require("vuex");

var _quillHtmlEditButton = require("quill-html-edit-button");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getDeliveryTypes } from '@/api/deliveryType'
window.Quill = _vue2Editor.Quill;
var _default = {
  components: {
    // Pagination
    VueEditor: _vue2Editor.VueEditor
  },
  data: function () {
    return {
      loading: false,
      userTenant: null,
      store: {
        id: null,
        name: '',
        tenantId: null,
        cuisine: '',
        order_prefix: ''
        /* ,
        deliverytypes: [] */

      },
      buttonText: 'Add',
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        }
      },
      tenants: [],
      // allDeliveryTypeChecked: false,
      isEdit: false,
      description: '',
      customModulesForEditor: [{
        alias: 'htmlEditButton',
        module: _quillHtmlEditButton.htmlEditButton
      }],
      editorSettings: {
        modules: {
          htmlEditButton: {}
        }
      }
      /* ,
      deliverytypes: [] */

    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['user'])
  },

  /* watch: {
    allDeliveryTypeChecked() {
      if (this.allDeliveryTypeChecked) {
        this.store.deliverytypes = []
        this.deliverytypes.forEach(deliverytype => {
          this.store.deliverytypes.push(deliverytype.id)
        })
      } else {
        this.store.deliverytypes = []
      }
    }
  }, */
  async created() {
    this.tenants = [this.user.tenant];
    /* getDeliveryTypes({ perPage: 9999 }).then(deliverytypes => {
      this.deliverytypes = deliverytypes.data
    }) */

    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
      this.userTenant = this.user.tenant;

      if (!this.user.tenant || !this.user.tenant.uuid) {
        // console.log('in')
        const userResponse = await (0, _user.getInfo)();
        this.userTenant = userResponse.data.tenant;
      }
    }
  },

  methods: {
    async fetchData() {
      this.loading = true;
      (0, _store.getStore)(this.$route.params.id).then(response => {
        this.store = response.data; // this.store.deliverytypes = this.store.deliverytypes.map(deliverytype => deliverytype.id)

        this.description = response.data.description;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          const param = {
            name: this.store.name,
            cuisine: this.store.cuisine,
            order_prefix: this.store.order_prefix,
            description: this.description
            /* ,
            deliverytypes: this.store.deliverytypes */

          };
          this.loading = true;

          if (this.$route.params.id) {
            param.id = this.$route.params.id;
            (0, _store.editStore)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Store updated.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/stores`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              }); // console.log(err)
            }).finally(() => {
              this.loading = false;
            });
          } else {
            (0, _store.createStore)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Store created.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/stores`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            }).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    }

  }
};
exports.default = _default;