var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.supplier,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.supplier.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "uuid", $$v)
                  },
                  expression: "supplier.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Supplier.Name"),
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("Supplier.RequiredNameMessage"),
                  },
                ],
              },
            },
            [
              _c("el-input", {
                on: { blur: _vm.onNameBlur },
                model: {
                  value: _vm.supplier.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "name", $$v)
                  },
                  expression: "supplier.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Supplier.ShortName"),
                prop: "shortname",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("Supplier.RequiredNameMessage"),
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "8" },
                model: {
                  value: _vm.supplier.shortname,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "shortname", $$v)
                  },
                  expression: "supplier.shortname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Supplier.ContactName"),
                prop: "contactName",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.supplier.contactName,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "contactName", $$v)
                  },
                  expression: "supplier.contactName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Supplier.Tel"), prop: "tel" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.supplier.tel,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "tel", $$v)
                  },
                  expression: "supplier.tel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Supplier.Email"),
                prop: "email",
                rules: [
                  {
                    type: "email",
                    message: _vm.$t("Supplier.IncorrectEmailMessage"),
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { type: "email" },
                model: {
                  value: _vm.supplier.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "email", $$v)
                  },
                  expression: "supplier.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Supplier.Address"), prop: "address" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.supplier.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "address", $$v)
                  },
                  expression: "supplier.address",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Supplier.Remark"), prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.supplier.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "remark", $$v)
                  },
                  expression: "supplier.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isOffline,
                    icon: "el-icon-success",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/suppliers" },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("Order.Cancel"))),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }