"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _vuex = require("vuex");

var _store = require("@/api/store");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  /* components: {
    Pagination
  }, */
  data() {
    return {
      storeId: this.$route.params.id,
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 9999
      },
      total: 0
    };
  },

  computed: { ...(0, _vuex.mapGetters)([// 'name',
    'user'])
  },

  async created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _store.getStoreProducts)({
        storeId: this.$route.params.id
      }).then(response => {
        this.list = response.data;
        this.total = response.data ? response.data.length : 0;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    deleteStoreProduct(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete store product?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        return (0, _store.deleteStoreProduct)(id).then(() => {
          (0, _elementUi.Message)({
            message: 'Store Product deleted',
            type: 'success',
            duration: 5 * 1000
          });
          return this.fetchData();
        }).catch(err => (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        }));
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;