var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              attrs: { to: "/stores/" + _vm.$route.params.id + "/products" },
            },
            [_vm._v(_vm._s(_vm.$t("StoreProductCombo.Back")))]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--default",
              class: { "is-disabled": _vm.isOffline },
              attrs: {
                to: _vm.isOnline
                  ? "/stores/" +
                    _vm.storeId +
                    "/products/" +
                    _vm.storeProductId +
                    "/combo/add"
                  : "#",
              },
            },
            [_vm._v(_vm._s(_vm.$t("StoreProductCombo.AddStoreProductCombo")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "summary-method": _vm.getSummaries,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.storeProduct.store.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.ProductName") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.storeProduct.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.RawMaterial") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rawMaterial.sku) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.RawMaterialPrice") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        $" +
                        _vm._s(scope.row.rawMaterial.defaultPrice) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.PercentageOf") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.percentage) + "%\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.PercentagePrice") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        $" +
                        _vm._s(
                          (
                            (scope.row.rawMaterial.defaultPrice *
                              scope.row.percentage) /
                            100
                          ).toFixed(4)
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.StoreStock") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.storeStock +
                            _vm.pluralize(
                              scope.row.rawMaterial.unit,
                              scope.row.storeStock
                            )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.WholeStock") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.stock +
                            _vm.pluralize(
                              scope.row.rawMaterial.unit,
                              scope.row.stock
                            )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("StoreProductCombo.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--warning",
                            attrs: {
                              to:
                                "/stores/" +
                                _vm.storeId +
                                "/products/" +
                                _vm.storeProductId +
                                "/combo/edit/" +
                                scope.row.uuid,
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("StoreProductCombo.Edit"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.isOffline,
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteStoreProductCombo(
                                  scope.row.uuid
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("StoreProductCombo.Delete")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }