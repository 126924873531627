var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.user.roles.find(function (role) {
        return ["Operation Manager", "Admin"].includes(role.name)
      })
        ? _c(
            "div",
            { staticClass: "action-container" },
            [
              _c(
                "el-form",
                { attrs: { inline: _vm.device !== "mobile" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "el-button el-button--success is-plain",
                          class: { "is-disabled": _vm.isOffline },
                          attrs: { to: _vm.isOnline ? "/stores/add" : "#" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-plus" }),
                          _vm._v(" " + _vm._s(_vm.$t("Order.AddStore"))),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "el-button el-button--primary",
                          attrs: { to: "/stores/salaryreport" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-s-operation" }),
                          _vm._v(" Advance Salary Report"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "No", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        class: {
                          "el-link el-link--danger":
                            _vm.rosterConfirmations.find(function (roster) {
                              return roster.store.uuid === scope.row.uuid
                            }),
                        },
                        attrs: {
                          title: _vm.rosterConfirmations.find(function (
                            roster
                          ) {
                            return roster.store.uuid === scope.row.uuid
                          })
                            ? "this store has unpublished roster"
                            : "",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.name) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--success",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: { to: "/stores/roster/" + scope.row.uuid },
                      },
                      [
                        _c("i", { staticClass: "el-icon-date" }),
                        _vm._v(" Roster"),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return [
                        "Store Manager",
                        "Operation Manager",
                        "Admin",
                      ].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              plain: "",
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                              icon: "el-icon-coordinate",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showLocationCode(scope.row.authCode)
                              },
                            },
                          },
                          [_vm._v("Location Code")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--warning",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: { to: "/stores/edit/" + scope.row.uuid },
                          },
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(" " + _vm._s(_vm.$t("Order.Edit"))),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return [
                        "Upload Sale",
                        "Operation Manager",
                        "Admin",
                      ].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "el-button el-button--success is-plain",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: {
                              to: "/stores/" + scope.row.uuid + "/sales",
                            },
                          },
                          [_vm._v("Sale")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return [
                        "Upload Sale",
                        "Operation Manager",
                        "Admin",
                      ].includes(role.name)
                    })
                      ? _c(
                          "router-link",
                          {
                            staticClass: "el-button el-button--primary",
                            class: {
                              "el-button--mini": _vm.device === "mobile",
                            },
                            attrs: {
                              to: "/stores/" + scope.row.uuid + "/products",
                            },
                          },
                          [_vm._v("Product")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              size:
                                _vm.device === "mobile" ? "mini" : "default",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteStore(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }